import React from 'react';

import { StartUpState } from "../constants"

import { auth0 } from '../environment';

import { telemetryService } from './../services/telemetryService'

import LogoText from './logoText';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

class StartUp extends React.Component {

    clearSessionCode() {
        Cookies.remove("sc");
        window.location.href = '/';
    }

    loginClick() {
        telemetryService.trackMilestone("Login clicked startup");
        window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f`;
    }

    renderState(startUpState) {
        let chars = [];
        let codeDisplay = {};

        switch (startUpState) {
            case StartUpState.TrySessionLookup:
                chars = Array.from(this.props.startUp.sessionCode);
                codeDisplay = chars.map(function (item, i) {
                    return (<div key={i} style={{ display: 'inline-block', padding: '10px', margin: '2px', border: '1px solid #666666', fontSize: '24px', backgroundColor: '#FFFFFF' }}>{item}</div>)
                })
                return (<div>
                    <h1 style={{ color: "#006600", fontSize: '16px' }}>Loading your session...</h1>
                    <div>
                        {codeDisplay}
                    </div>
                </div>);

            case StartUpState.SessionLookupComplete:
                chars = Array.from(this.props.startUp.sessionCode);
                codeDisplay = chars.map(function (item) {
                    return (<div style={{ display: 'inline-block', padding: '10px', margin: '2px', border: '1px solid #666666', fontSize: '24px', backgroundColor: '#32CD32' }}>{item}</div>)
                })
                return (<div>
                    <h1 style={{ color: "#006600", fontSize: '16px' }}>We found your session code :)</h1>
                    <div>
                        {codeDisplay}
                    </div>
                </div>);
            case StartUpState.SessionLookupFailed:
            case StartUpState.SessionLookupStale:
            default:
                chars = Array.from(this.props.startUp.sessionCode);
                codeDisplay = chars.map(function (item, i) {
                    return (<div key={i} style={{ display: 'inline-block', padding: '10px', margin: '2px', border: '1px solid #666666', fontSize: '24px', backgroundColor: '#CD3232' }}>{item}</div>)
                })
                return (<div>
                    <h1 style={{ color: "#CC0000", fontSize: '16px' }}>Session code not found :(</h1>
                    <div>
                        {codeDisplay}
                    </div>

                    <div style={{ position: 'absolute', bottom: '0px', width: '100%' }}>
                        <div style={{ background: 'yellowgreen', textAlign: 'center' }}>
                            <div style={{ padding: '20px', textAlign: 'center' }}>
                                <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '40px', color: 'white' }} />
                                <h1 style={{ color: "white", fontSize: '22px', paddingTop: '15px', margin: '0' }}>
                                    Match session code on app
                                </h1>
                            </div>

                            <div style={{ backgroundColor: 'white', borderRadius: '15px', color: '#783F8E', fontSize: '14px', margin: '0px 10px 10px 10px', padding: '10px', maxWidth: '500px', display: 'inline-block' }}>
                                <p>Make sure the session code shown on the PracticeLoop app matches this one.</p>
                                <p>If not, scan the QR code again or <a href="/" onClick={this.clearSessionCode}>enter the session code manually</a></p>
                                <p>If the App is running, and the session code matches</p>
                                <button onClick={() => { document.location.reload() }} style={{ textAlign: "center", marginBottom: '5px', textDecoration: 'none', backgroundColor: 'rgb(120, 63, 142)', color: 'white', padding: '5px 10px', fontSize: '14px', borderRadius: '5px', display: 'inline', fontWeight: 'bold' }} >
                                    Click to try again
                                </button>
                            </div>
                            <p></p>
                            <a href="https://www.practiceloop.com/setup.html" target="_blank" rel="noopener noreferrer" style={{ display: "block", padding: "10px 0 20px 0", textAlign: "center", color:'#783F8E' }}>Full setup guide</a>
                        </div>
                    </div>
                </div>);
        }
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: "70px" }}>
                    <img src="logo_dark.png" alt="Logo" style={{ width: '70px', height: 'auto', display: 'inline-block', marginBottom: '5px' }} />
                    <br />
                    <LogoText style={{ fontSize: "32px" }}></LogoText>
                    <div style={{ fontSize: '16px', color: 'rgb(120, 63, 142)', marginTop: "5px" }}>viewer</div>
                    <br />
                    <br />
                    {this.renderState(this.props.startUp.state)}
                </div>

                <div style={{ fontSize: '12px', marginTop: '10px' }}>
                    <a href="https://www.practiceloop.com/setup.html" target="_blank" rel="noopener noreferrer">Setup guide</a>&nbsp;|&nbsp;
                    <a href="https://www.practiceloop.com/privacy.html" target="_blank" rel="noopener noreferrer">Privacy policy</a>&nbsp;|&nbsp;
                    <a href="https://www.practiceloop.com/terms.html" target="_blank" rel="noopener noreferrer">Terms of service</a>
                </div>
            </div>
        )
    }
}

export default StartUp;