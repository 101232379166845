let servicesHost = "http://localhost:7071/api";
let auth0 = {
    host : "https://dev-practiceloop.au.auth0.com", 
    redirect: "http://local.practiceloop.com:3000", 
    clientId : "dgqRm592h7fZYe8iiPUtf9xuROPxJ0ff"
};

let auth0Prod = {
    host : "https://practiceloop.au.auth0.com", 
    redirect: "http://viewer.practiceloop.com", 
    clientId : "hqKcbtSeOdbQeUcHEKyQcM7SNAFGxMwh"
};

let shop = {uri: "http://localhost:3001"}
let google = {trackingId: ""}

const hostname = window && window.location && window.location.hostname;

// Local development
if(hostname === 'local.scratchtime.golf' || hostname === 'local.practiceloop.com') 
{
    servicesHost = "http://localhost:7071/api";
    //servicesHost = "https://scratchtime-api-staging.azurewebsites.net/api";
    servicesHost = "https://scratchtime-api.azurewebsites.net/api";
    //auth0 = {};
}
// Preview environment - used for testing client deployments
else if(hostname === 'previewer.scratchtime.golf' || hostname === 'previewer.practiceloop.com') {
    servicesHost = "https://scratchtime-api.azurewebsites.net/api";
    auth0 = auth0Prod;
    auth0.redirect = "http://previewer.practiceloop.com";
    shop.uri = "https://www.practiceloop.com";
    //auth0 = {};
}
// Staging environment - used for testing API deployments
else if(hostname === 'staging.scratchtime.golf' || hostname === 'staging.practiceloop.com') {
    servicesHost = "https://scratchtime-api-staging.azurewebsites.net/api";
    auth0 = auth0Prod;
    auth0.redirect = "http://staging.practiceloop.com";
    shop.uri = "https://www.practiceloop.com";
}
// Production environment
else {
    servicesHost = "https://scratchtime-api.azurewebsites.net/api";
    auth0 = auth0Prod;
    shop.uri = "https://www.practiceloop.com";
}

export {servicesHost, auth0, shop, google}