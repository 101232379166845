import { put, call, takeLatest, select } from 'redux-saga/effects';

import { telemetryService } from '../services/telemetryService';
import { setSession, setSessionFailed } from '../actions/startUpActions';
import { servicesHost } from '../environment';
import { Actions } from '../constants';

const axios = require('axios');

/////// EXPORTED SAGAS /////////

function* getSessionFromCode() {
  yield takeLatest(Actions.StartUp.SetSessionCode, doGetSessionFromCode);
}

/*function* getRefreshTokenFromApp(){
  yield takeLatest(Actions.StartUp.SetIpFromCode, doGetRefreshTokenFromApp);
}*/
//Cookies.set("rt", response.refresh);

/////// END EXPORTED SAGAS /////////

/*
function* retryIpAddress() {
  yield delay(2000);
  yield call(onGetIpAddress);
}*/

function* doGetSessionFromCode() {
  let sessionInfo = "";
  const getSessionCode = (state) => state.startUp.sessionCode;

  try {
    const sessionCode = yield select(getSessionCode);
    telemetryService.trackEvent("Try tether IP address from session code");
    sessionInfo = yield call(() => performGetSessionFromCode(sessionCode));
    telemetryService.trackMilestone("Got tether IP address from session code");
  }
  catch (e) {
    telemetryService.trackEvent("Try tether IP address failed");
    yield put(setSessionFailed(e));
    return;
  }

  if (sessionInfo) {
    yield put(setSession(sessionInfo));
  } else {
    yield put(setSessionFailed());
  }
}

function performGetSessionFromCode(code) {
  return axios.get(`${servicesHost}/sessiontemp?go=${code}`).then(({ data }) => {
      return data;
    })
}


export { getSessionFromCode }