import Cookies from 'js-cookie';
import { put, call, takeLatest, take, delay, select, takeEvery } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';
import { telemetryService } from '../services/telemetryService';
import { setTetherIpAddress, tetherIpAddressFailed, webSocketClosed, setIdToken, getIdTokenFailed } from '../actions/connectionActions'
import { imageReceived, connectionStateChanged } from '../actions/imageActions';
import { auth0, servicesHost } from '../environment';
import { Actions } from '../constants';

const axios = require('axios');


/////// EXPORTED SAGAS /////////

// TOKENS
function* getIdTokenSaga() {
  yield takeLatest(Actions.Connection.GetIdToken, onGetToken);
}

function* refreshIdTokenSaga() {
  yield takeLatest(Actions.Connection.RefreshIdToken, onGetToken);
}

// IP ADDRESS
function* getIPAddressSaga() {
  yield takeLatest(Actions.StartUp.SetSession, onGetIpAddress);
}

function* refreshIpAddressSaga() {
  yield takeLatest(Actions.Connection.GetTetherIpFailed, retryIpAddress);
}

// WEB SOCKET CONNECTION
function* refreshConnectionSaga() {
  yield takeLatest(Actions.Websocket.Closed, retryIpAddress);
}

function* connectWebsocketSaga() {
  yield takeEvery(Actions.Connection.SetTetherIp, setupWebsocket);
}

function* startPollingSaga() {
  yield takeEvery(Actions.Connection.SetTetherIp, pollWebRTC);
}

/////// END EXPORTED SAGAS /////////


function* retryIpAddress() {
  yield delay(2000);
  yield call(onGetIpAddress);
}

function* setupWebsocket(action) {
  // Version 2 uses polling rather than websockets, so if we're above V1 then don't set up the websocket
  if (action.appVersion > 1) {
    return;
  }
  const channel = yield call(() => createEventChannel(action.ipAddress));

  while (true) {
    try {
      const eventAction = yield take(channel);
      yield put(eventAction);
    }
    catch (sockErr) {
      channel.close();
      yield put(webSocketClosed());
    }
  }
}

function createEventChannel(ipAddress) {
  let mySocket;

  return eventChannel(emit => {
    // Establish web socket connection.  If this fails onClose is called and retry happens there
    //emit(connectionStateChanged("pending"))
    telemetryService.trackEvent("Opening web socket:" + ipAddress);
    mySocket = new WebSocket(`ws://${ipAddress}:8111`);
    //mySocket.binaryType = "arraybuffer";      // We want to get images

    // Set up event handlers for web socket client events and fire actions to respond
    mySocket.onmessage = (e) => {
      let imageData = new Blob([e.data]);
      emit(imageReceived(imageData));
    };
    mySocket.onopen = () => {
      telemetryService.trackMilestone("Connected to App");
      emit(connectionStateChanged("connected"));
    }

    mySocket.onerror = (e) => {
      // set state so UI can respond
      emit(webSocketClosed());
    }

    mySocket.onclose = (e) => {
      // set state so UI can respond
      emit(webSocketClosed());
    }

    return () => {
      mySocket.close();
    };
  }, buffers.sliding(100));
}

function createEventChannelWebRTC(ipAddress) {
  let mySocket;
  let localPeerConnection;
  let dataChannel;

  const gotAnswerDescription = (answer) => {
    send('send_answer', {
      answer: answer,
    });

    localPeerConnection.setLocalDescription(answer);
  };

  const gotLocalIceCandidateAnswer = (event) => {
    // gathering candidate finished, send complete sdp
    if(event.candidate) {
      let answer = event.candidate;
      send('send_ice_candidate', {
        candidate: answer,
      });
    }
  };

  const send = (type, body) => {
    mySocket.send(JSON.stringify({
      type,
      body,
    }))
  }

  return eventChannel(emit => {
    // Establish web socket connection.  If this fails onClose is called and retry happens there
    //emit(connectionStateChanged("pending"))
    telemetryService.trackEvent("Opening web socket:" + ipAddress);
    mySocket = new WebSocket(`ws://${ipAddress}:8111`);

    // Set up event handlers for web socket client events and fire actions to respond
    mySocket.onmessage = (m) => {
      try{
        let message = JSON.parse(m.data);
        switch(message.type) {
          case 'offer_sdp_received':
            let offer = message.body;

            // When there's a new offer, we will make a new connection, so clean up old one if it exists
            if(localPeerConnection){
              localPeerConnection.close();
              localPeerConnection = undefined;
            }
            localPeerConnection = new RTCPeerConnection();

            localPeerConnection.addEventListener('datachannel', event => {

              dataChannel = event.channel;

              dataChannel.onerror = function (error) {
                emit(webSocketClosed());
              };

              dataChannel.onmessage = function (event) {
                let imageData = new Blob([event.data]);
                emit(imageReceived(imageData));
              };

            });

            localPeerConnection.onicecandidate = gotLocalIceCandidateAnswer;
            localPeerConnection.setRemoteDescription(offer);
            localPeerConnection.createAnswer().then(gotAnswerDescription);
            break;
          case 'ice_candidate_received':
            try {
              localPeerConnection.addIceCandidate(message.body);
            }
            catch{
              telemetryService.trackMilestone("error adding ice candidate");
            }
            break;

          default:
            break;
        }
      } catch(e){
        console.log('Message is not JSON', m);
      }

    };
    mySocket.onopen = () => {
      telemetryService.trackMilestone("Connected to App");
      emit(connectionStateChanged("connected"));
    }

    mySocket.onerror = (e) => {
      // set state so UI can respond
      emit(webSocketClosed());
    }

    mySocket.onclose = (e) => {
      // set state so UI can respond
      emit(webSocketClosed());
    }

    return () => {
      mySocket.close();
    };
  }, buffers.sliding(100));
}

function* onGetIpAddress() {
  let viewerSession = "";
  const getSessionCode = (state) => state.startUp.sessionCode;

  try {
    const sessionCode = yield select(getSessionCode);
    //telemetryService.trackEvent("Try tether IP address");
    viewerSession = yield call(() => performIpRequest(sessionCode));
    //telemetryService.trackMilestone("Got tether IP address");
  }
  catch (e) {
    //telemetryService.trackEvent("Try tether IP address failed");
    yield put(tetherIpAddressFailed(e));
    return;
  }

  // Succeeds if:
  // We found a viewer session
  // The viewer session has an IP address
  // There's either no app_ping_time (older version of the app), or the time is within the last hour
  if (viewerSession && viewerSession.ip !== '' && (process.env.NODE_ENV !== 'production' || (!viewerSession.app_ping_time || viewerSession.app_ping_time > ((Date.now() / 1000) - 3600)))) {
  //if (viewerSession && viewerSession.ip !== '') {
    yield put(setTetherIpAddress(viewerSession.ip, viewerSession.app_version));
  } else {
    yield put(tetherIpAddressFailed());
  }
}

function performIpRequest(code) {
  return axios.get(`${servicesHost}/sessiontemp?go=${code}`).then(({ data }) => {
      return data;
    })
}

function* onGetToken(action) {
  let response = "";
  let type = "";
  let code = "";

  if (action.code) // We have a code, so exchange that for a token
  {
    type = "authorization_code";
    code = action.code;
  }
  else if (action.refreshToken) {
    type = "refresh_token";
    code = action.refreshToken;
  }
  else {
    telemetryService.trackEvent("Invalid code or refresh token");
    return;
  }

  try {
    telemetryService.trackEvent("Try getting token from code");
    response = yield call(() => getTokenFromCode(code, type));
    telemetryService.trackEvent("Got token");
    Cookies.set("rt", response.refresh);
    yield put(setIdToken(response.token));
  }
  catch (e) {
    telemetryService.trackEvent("Try id token failed");
    yield put(getIdTokenFailed(e));
    return;
  }
}

function getTokenFromCode(code, type) {
  return axios({
    method: "post",
    url: `${servicesHost}/token`,
    data: { "code": code, "redirect_uri": auth0.redirect, "type" : type },
    headers: { "Content-Type": "application/json" }
  }).then(({ data }) => {
    return data;
  })
}

function* pollWebRTC(action) {
  // Version 1 uses websockets, so if we're below V2 then don't poll images
  if (action.appVersion <= 1) {
    return;
  }
  const channel = yield call(() => createEventChannelWebRTC(action.ipAddress));

  while (true) {
    try {
      const eventAction = yield take(channel);
      yield put(eventAction);
    }
    catch (sockErr) {
      channel.close();
      yield put(webSocketClosed());
    }
  }
}

export { connectWebsocketSaga, getIPAddressSaga, refreshConnectionSaga, refreshIpAddressSaga, getIdTokenSaga, refreshIdTokenSaga, startPollingSaga }