import React from 'react';
import ReactModal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faShare, faFolderOpen, faTimes, faSignInAlt, faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import { servicesHost } from '../../environment';
import { telemetryService } from '../../services/telemetryService'

const MODE_PAUSE = "pause";

const dialogStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '50%',
        minWidth: '340px',
        minHeight: '340px',
        padding: '10px'
    }
};


class CheckPoint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: (this.props.checkpoint && this.props.checkpoint.name) ? this.props.checkpoint.name : "New checkpoint",
            showCheckpointModal: false,
            hasChanged: false
        };

        this.updateName = this.updateName.bind(this);
        this.saveName = this.saveName.bind(this);
        this.openCheckpointModal = this.openCheckpointModal.bind(this);
        this.closeCheckpointModal = this.closeCheckpointModal.bind(this);
        this.onSaveCheckpoint = this.onSaveCheckpoint.bind(this);
        this.setImage = this.setImage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.checkpoint && nextProps.checkpoint.name !== this.state.name) {
            this.setState({
                name: (nextProps.checkpoint.name) ? nextProps.checkpoint.name : "New checkpoint",
                hasChanged: true
            });
        }
    }

    login() {
        telemetryService.trackMilestone("User login checkpoint click");
        window.location.href = '/login';
      }

    // Open the modal
    openCheckpointModal() {
        this.props.onFetchLibrary();
        this.setState({ showCheckpointModal: true });
    }

    // Close the modal
    closeCheckpointModal() {
        this.setState({ showCheckpointModal: false });
    }

    // Handles the typing of the new name, sets it in to the component state but not the store
    updateName(event) {
        this.setState({ name: event.target.value });
    }

    /* UPDATING THE PRACTICE VIEW IN THE STORE */
    // Commits the name change to the store
    saveName() {
        this.setState({ hasChanged: true });     // When you type you need to save it again
        this.props.onSetCheckpoint(this.props.index, undefined, this.state.name);
    }

    // Sets whatever the current image on the delayed screen is in to the practice view
    setImage() {
        this.setState({ hasChanged: true });     // When you set the image you need to save it again
        this.props.onSetCurrentImage(this.props.index);
    }

    // Sets a checkpoint from the library in to the current practice view
    async onSelectCheckpoint(checkpoint) {
        let url = `${servicesHost}/checkpoints?token=${this.props.token}&name=${encodeURI(checkpoint.name)}`;
        let blob = await fetch(url)
            .then(r => r.blob())
            .catch(e => console.log(e));

        this.props.onSetCheckpoint(this.props.index, blob, checkpoint.name);
        this.closeCheckpointModal();
    }

    // Saves what is in the practice view as a checkpoint
    onSaveCheckpoint() {
        this.setState({ hasChanged: false });    // When you save it, you don't need to save it any more
        this.props.onSaveCheckpoint(this.props.checkpoint);
    }

    render() {
        let size = { width: "100%", height: "auto", titleWidth: "100%" }
        let checkpoint = this.props.checkpoint;

        // Check if there are any checkpoints saved
        let list = this.props.checkpointLibrary.items.length > 0 ?
            // There are, so map them in to a list
            this.props.checkpointLibrary.items.map((c) =>
                <button key={c.name} onClick={() => this.onSelectCheckpoint(c)} style={{ verticalAlign: 'middle', marginBottom: '5px', border: '1px solid #999999', backgroundColor: '#EEEEEE', padding: '0px', textAlign: 'left', display: 'block', width: '100%', minWidth: '350px' }}>
                    <img style={{ width: '48px', height: '64px', verticalAlign: 'middle' }} src={`${servicesHost}/checkpoints?token=${this.props.token}&name=${encodeURI(c.name)}`} alt={c.name}></img>
                    <div style={{ verticalAlign: 'middle', display: 'inline-block', marginLeft: '10px' }}>{c.name}</div>
                </button>) :
            // There are no checkpoints, so show a nice message
            <div style={{ border: "1px solid #999999", backgroundColor: "#EEEEEE", padding: '10px', marginTop: '20px' }}>
                <div style={{ textAlign: 'center', fontSize: '14px', marginBottom: "10px", color: "rgb(120, 63, 142)" }}>You haven't created any checkpoints yet</div>
                <div style={{ fontSize: '12px' }}>Close this dialog and create one.</div>
            </div>

        return (<div>
            <div style={size}>
                {this.props.token ?
                    checkpoint ?
                    <div style={{ position: 'relative' }}>
                        {checkpoint.image ?
                        <img src={checkpoint.image} style={size} alt={checkpoint.name} />
                        :
                        <div style={{ ...size, textAlign: 'center', paddingTop: '25%'}}>
                            <div>Pause delayed stream and set checkpoint image</div>
                            </div>
    }
                        <div style={{ position: 'absolute', top: '0px', width: size.width }}>
                            <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderLeft: '3px solid #333333', color: '#333333', height: '40px', marginRight: '50px' }}>
                                <input style={{ height: '36px', width: size.titleWidth, fontSize: '14px', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.0)', border: 'none' }} type="text" value={this.state.name} onChange={this.updateName} onBlur={this.saveName}></input>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', top: '0px', right: '0px', textAlign: 'center', width: '40px', minHeight: '40px', padding: '5px 5px', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                        
                        {this.props.playbackMode === MODE_PAUSE ?
                                <button onClick={this.setImage} style={{ height: '38px', width: '38px', fontSize: '20px', color: '#333333', padding: "0px", marginBottom: '10px'}}><FontAwesomeIcon icon={faImage} /><div style={{ fontSize: '10px' }}>Set</div></button> :
                                <span></span>
                            }
                            {this.state.hasChanged ?
                                <button onClick={this.onSaveCheckpoint} style={{ height: '38px', width: '38px', fontSize: '20px', color: '#333333', padding: "0px",marginBottom: '10px'}}><FontAwesomeIcon icon={faShare} /><div style={{ fontSize: '10px' }}>Save</div></button> :
                                <span></span>
                            }
                            <button onClick={() => this.props.onSetCheckpoint(this.props.index, null, "New checkpoint")} style={{ height: '38px', width: '38px', fontSize: '20px', color: '#333333', padding: "0px"}}>
                                <div><FontAwesomeIcon icon={faFolderPlus} /></div>
                                <div style={{ fontSize: '10px' }}>New</div>
                            </button>
                            <button onClick={this.openCheckpointModal} style={{ height: '38px', width: '38px', fontSize: '20px', color: '#333333', padding: "0px"}}>
                                <div><FontAwesomeIcon icon={faFolderOpen} /></div>
                                <div style={{ fontSize: '10px' }}>Open</div>
                            </button>
                        </div>
                    </div>
                    :
                    <div style={{ ...size, textAlign: 'center', paddingTop: '25%'}}>
                        <h3 style={{ color: "#783F8E" }}>{this.props.children}</h3>
                        {this.props.playbackMode === MODE_PAUSE && 1==2 ?
                            <div style={{ marginTop: '10px' }}>
                                <button onClick={() => this.props.onSetCurrentImage(this.props.index)} style={{ fontSize: '24px', color: 'green' }}><FontAwesomeIcon icon={faImage} /><div style={{ fontSize: '12px' }}>Set image</div></button>
                                <button onClick={this.openCheckpointModal} style={{ fontSize: '24px', color: 'green' }}><FontAwesomeIcon icon={faFolderOpen} /><div style={{ fontSize: '12px' }}>Open</div></button>
                            </div> :
                            <div>
                                <div style={{ marginTop: '10px' }}>
                                <button onClick={() => this.props.onSetCheckpoint(this.props.index, null, "New checkpoint")} style={{ fontSize: '30px', color: '#783F8E' }}><FontAwesomeIcon icon={faFolderPlus} /><div style={{ fontSize: '12px' }}>New</div></button>&nbsp;&nbsp;&nbsp;
                                    <button onClick={this.openCheckpointModal} style={{ fontSize: '30px', color: '#783F8E' }}><FontAwesomeIcon icon={faFolderOpen} /><div style={{ fontSize: '12px' }}>Open</div></button>
                                </div>
                            </div>}
                    </div>
                    :
                    <div style={{ ...size, textAlign: 'center', paddingTop: '25%'}}>
                        <h2 style={{ color: 'rgb(120, 63, 142)', fontSize: '16px' }}>Log in to create and save checkpoints</h2>
                        <button onClick={this.login} style={{ textDecoration: 'none', backgroundColor: 'rgb(120, 63, 142)', color: 'white', padding: '15px 30px', fontSize: '14px', borderRadius: '5px', display: 'inline-block', fontWeight: 'bold' }} >
                            Login&nbsp;&nbsp;<FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon>
                        </button>
                        <p></p>
                    </div>}
            </div>
            <ReactModal
                isOpen={this.state.showCheckpointModal}
                style={dialogStyle}
                onRequestClose={this.closeCheckpointModal}
                shouldCloseOnOverlayClick={true}
            >
                <div style={{ fontSize: '13px', margin: '0px 0 15px 0', fontWeight: 'bold' }}><span onClick={this.closeCheckpointModal} style={{ fontSize: '24px', float: 'right', marginTop: '-7px' }}><FontAwesomeIcon icon={faTimes} /></span>
                    <h3 style={{ color: "rgb(120, 63, 142)", marginTop: "4px" }}>Open a checkpoint</h3>
                </div>
                {this.props.checkpointLibrary.isLoading ? <div>Loading</div> :
                    <div>{list}</div>
                }
            </ReactModal>
        </div>)
    }
}

export default CheckPoint;