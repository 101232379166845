import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

import { auth0 } from '../environment';

import { telemetryService } from './../services/telemetryService'
import LogoText from './logoText';

class Login extends React.Component {
    loginClick() {
        telemetryService.trackMilestone("Login clicked");
        window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f`;
    }

    backHome() {
        window.location.href = '/';
      }

    createAccount() {
        telemetryService.trackMilestone("Create account clicked");
        window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f&screen_hint=signup`;
    }
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '50px' }}>
                    <div style={{ width: '416px', textAlign: 'center', marginBottom: "70px" }}>
                        <img src="logo_dark.png" alt="Logo" style={{ width: '70px', height: 'auto', display: 'inline-block', marginBottom: '5px' }} />
                        <br />
                        <LogoText style={{fontSize: "32px"}}></LogoText>
                        <div style={{ fontSize: '16px', color: 'rgb(120, 63, 142)', marginTop:"5px" }}>viewer</div>
                        <br />
                        <br/>
                        <button onClick={this.loginClick} style={{ textDecoration: 'none', backgroundColor: 'rgb(120, 63, 142)', color: 'white', padding: '15px 30px', fontSize: '14px', borderRadius: '5px', display: 'inline-block', fontWeight: 'bold' }} >
                            Login&nbsp;&nbsp;<FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon>
                        </button>
                        <div style={{ margin: '10px' }}>
                        </div>
                    or <button style={{ textDecoration: 'underline', color: 'rgb(120, 63, 142)' }} onClick={this.createAccount}>
                            Create a Free account
                    </button>

                        <div style={{ color: 'rgb(120, 63, 142)', marginTop: '20px', display: 'block', textAlign: 'center', fontSize: '12px', width: '90%', maxWidth: "800px", padding: '10px' }}>
                            <strong>LOG IN TO ACCESS MORE FEATURES</strong>
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Create checkpoints.</li>
                                    <li>Save user settings.</li>
                                </ul>
                            </div>
                        </div>

                        <button style={{ textDecoration: 'underline', color: 'rgb(120, 63, 142)' }} onClick={this.backHome}>
                            &lt; Back
                    </button>
                    </div>
                </div>
                <div style={{ fontSize: '12px', marginTop: '20px' }}>
                    <a href="https://www.practiceloop.com/setup.html" target="_blank" rel="noopener noreferrer">Setup guide</a>&nbsp;|&nbsp;
                    <a href="https://www.practiceloop.com/privacy.html" target="_blank" rel="noopener noreferrer">Privacy policy</a>&nbsp;|&nbsp;
                    <a href="https://www.practiceloop.com/terms.html" target="_blank" rel="noopener noreferrer">Terms of service</a>
                </div>

            </div>
        )
    }
}

export default Login;