import { combineReducers } from 'redux'
import practiceView from './practiceView'
import user from './user'
import brand from './brand'
import image from './image'
import checkpointLibrary from './checkpointLibrary'
import startUp from './startup'

export default combineReducers({
    practiceView,
    user,
    brand,
    image,
    checkpointLibrary,
    startUp
})
