import { connect } from 'react-redux'
import { enterRealtimeMode, enterYoutubeMode, enterSingleCheckpointMode, enterDoubleCheckpointMode, setCheckpoint, toggleMainView } from '../actions/practiceView'
import { setDelayPeriod } from '../actions/imageActions' 
import ImagePanel from '../components/imageView/imagePanel'

const mapDispatchToProps = dispatch => {
  return {
      onEnterRealtimeMode : (checkpoint) => {
        dispatch(enterRealtimeMode(checkpoint))
      },
      onEnterYoutubeMode : () => {
        dispatch(enterYoutubeMode())
      },
      onEnterSingleCheckpointMode : () => {
        dispatch(enterSingleCheckpointMode())
      },
      onEnterDoubleCheckpointMode : () => {
        dispatch(enterDoubleCheckpointMode())
      },
      onSetCheckpoint : (checkpointNum, image, name) => {
        dispatch(setCheckpoint(checkpointNum, image, name))
      },
      onToggleMainView : () => {
        dispatch(toggleMainView())
      },
      onSetDelayPeriod : (delay) => {
        dispatch(setDelayPeriod(delay))
      }
  }
}

const mapStateToProps = (state = {}, ownProps)  => {
  return {
      image: state.image || {},
      brand: state.brand || {},
      practiceView: state.practiceView,
      subscription: state.startUp.subscription,
      lessonId: ownProps.lessonId,
      refId: ownProps.refId
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagePanel)
