
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { telemetryService } from '../services/telemetryService';
import { servicesHost } from '../environment';
import { setLibrary } from '../actions/checkpointLibrary';

const axios = require('axios');

function* saveCheckpoint() {
  yield takeLatest('CHECKPOINTLIBRARY/SAVE_CHECKPOINT', onSaveCheckpoint);
}

function* fetchLibrary() {
  yield takeLatest('CHECKPOINTLIBRARY/FETCH_LIBRARY', onFetchCheckpoints);
}

function* onSaveCheckpoint(action) {
  const getToken = (state) => state.user.token;

  try {
    const token = yield select(getToken);
    telemetryService.trackEvent(`Trying to save checkpoint: ${action.checkpoint.name}`);
    yield call(() => postCheckpoint(token, action.checkpoint));
    telemetryService.trackEvent(`Checkpoint saved: ${action.checkpoint.name}`);
  }
  catch (e) {
    telemetryService.trackEvent(`Checkpoint saving failed: ${action.checkpoint.name}`);
    return;
  }
}

function* onFetchCheckpoints() {
  const getToken = (state) => state.user.token;

  try {
    const token = yield select(getToken);
    telemetryService.trackEvent("Trying to load checkpoints");
    var checkpoints = yield call(() => fetchCheckpoints(token));
    telemetryService.trackEvent("Loaded checkpoints");
    yield put(setLibrary(checkpoints));
  }
  catch (e) {
    telemetryService.trackEvent("Load checkpoints failed");
    return;
  }
}

function fetchCheckpoints(token) {
  return axios({
    method: "get",
    url: `${servicesHost}/checkpoints`,
    headers: { "Authorization": `Bearer ${token}` },
  }).then(({ data }) => {
    return data;
  })
}


function postCheckpoint(token, checkpoint) {
  return axios({
    method: "post",
    url: `${servicesHost}/checkpoints`,
    data: {"name": checkpoint.name, "image": checkpoint.image},
    headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
  }).then(({ data }) => {
    return data;
  })
}


export { fetchLibrary, saveCheckpoint }