import { connect } from 'react-redux'
import RealTimePanel from '../components/imageView/realTimePanel'

const mapStateToProps = (state = {}, ownProps)  => {
  return {
      image: state.image || {},
      size: ownProps.size || "big"
  }
}

export default connect(
  mapStateToProps
)(RealTimePanel)
