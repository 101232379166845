import React from 'react';
import './setupHelp.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faLaptop, faWifi, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

class SetupHelp extends React.Component {
  render() {
    var wifiColor = "#783F8E";
    var appColor = "#783F8E";
    var helpMessage = <div></div>;

    var statusText = "Please wait... It might take a few seconds";

    if (this.props.connectionState === "get-ip-failed") {
      appColor = "#999999";
      statusText = "Failed to detect app. Retrying...";

      helpMessage = <div style={{ background: 'yellowgreen', textAlign: 'center' }}>
        <div style={{ padding: '20px',textAlign: 'center' }}>
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '40px', color: 'white' }} />
          <h1 style={{ color: "white", fontSize: '22px', paddingTop: '15px', margin: '0' }}>
          Ensure PracticeLoop is installed and running on another device
          </h1>
        </div>
        <p></p>
        <div style={{ backgroundColor: 'white', borderRadius: '15px', color: '#783F8E', fontSize: '14px', margin: '0px 10px 10px 10px', padding: '10px', maxWidth: '500px', display: 'inline-block' }}>The PracticeLoop App needs to be running on a different device (phone).<br /><br />
        Start the app if you have it, or scan to install.
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <img src="install_qr.png" style={{ width: '100px', height: '100px' }} alt="Scan to install the app" /> <br />
          </div>
          <a href="https://www.practiceloop.com/install">www.practiceloop.com/install</a>
        </div>
        <a href="https://www.practiceloop.com/setup.html" target="_blank" rel="noopener noreferrer" style={{ display: "block", padding: "10px 0 20px 0", textAlign: "center", color:'#783F8E' }}>Full setup guide</a>
      </div>;

    }
    else if (this.props.connectionState === "failed") {
      wifiColor = "#999999";
      statusText = "Wifi connection not found. Retrying..."
      helpMessage = <div style={{ background: 'yellowgreen', textAlign: 'center' }}>
        <div style={{ padding: '20px',textAlign: 'center' }}>
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '40px', color: 'white' }} />
          <h1 style={{ color: "white", fontSize: '22px', paddingTop: '15px', margin: '0' }}>
            Ensure this device is connected to the Wifi hotspot on your phone
          </h1>
        </div>
        <p></p>
        <div style={{ backgroundColor: 'white', borderRadius: '15px', color: '#783F8E', fontSize: '14px', margin: '0px 10px 10px 10px', padding: '10px', maxWidth: '500px', display: 'inline-block' }}>We have detected that the PracticeLoop app is running.  Ensure you have connected this device to the WiFi hotspot on the phone that is running the app.</div>
        <a href="https://www.practiceloop.com/setup.html" target="_blank" rel="noopener noreferrer" style={{ display: "block", padding: "10px 0 20px 0", textAlign: "center", color:'#783F8E' }}>Full setup guide</a>
      </div>;
    }

    return (
      <div style={{ paddingTop: '20px', width: '100%', height: '100%' }}>
        <div className="main-body" style={this.props.brand.styles.content}>
          <div>
            <h1 className="pulsate" style={this.props.brand.styles.connecting}>Connecting to App</h1>
            <div style={{ paddingTop: '10px', paddingBottom: '0px' }}>
              <FontAwesomeIcon icon={faLaptop} style={{ color: '#783F8E', fontSize: '60px' }} className="show-on-landscape-only"></FontAwesomeIcon>
              <FontAwesomeIcon icon={faMobileAlt} style={{ color: '#783F8E', fontSize: '60px' }} className="show-on-portrait-only" ></FontAwesomeIcon>
              <FontAwesomeIcon icon={faWifi} className="wifiSymbol" style={{ color: wifiColor }} />
              <FontAwesomeIcon icon={faMobileAlt} style={{ color: appColor, fontSize: '60px' }}></FontAwesomeIcon>
              <br /><br />
              <h1 className="sub-heading pulsate" style={this.props.brand.styles.subHeading}><i>{statusText}</i></h1>
            </div>
          </div>
          <div style={{ position: 'absolute', bottom: '0px', width: '100%' }}>
            {helpMessage}
          </div>
        </div>
      </div>
    )
  }
}

export default SetupHelp;