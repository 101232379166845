import React, { useState } from 'react';

import { telemetryService } from '../services/telemetryService'
import LogoText from './logoText';

function EnterSessionCode() {
    const [sessCode, setSessCode] = useState('');

    const sessionGo = () => {
        if(sessCode.length === 5) {
            telemetryService.trackMilestone(`Session code entered: ${sessCode}`);
            document.location.href = `/?go=${sessCode.toUpperCase()}`;
        }
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '50px' }}>
                <div style={{ width: '416px', textAlign: 'center', marginBottom: "70px" }}>
                    <img src="logo_dark.png" alt="Logo" style={{ width: '70px', height: 'auto', display: 'inline-block', marginBottom: '5px' }} />
                    <br />
                    <LogoText style={{ fontSize: "32px" }}></LogoText>
                    <div style={{ fontSize: '16px', color: 'rgb(120, 63, 142)', marginTop: "5px" }}>viewer</div>
                    <br />
                    <br />
                    <h3 style={{ color: 'rgb(120, 63, 142)' }}>ENTER SESSION CODE</h3>
                    <form onSubmit={e => {e.preventDefault(); sessionGo()}}>
                        <input type="text" maxLength="5" style={{ padding: '10px', fontSize: '30px', letterSpacing: '10px', textAlign: 'center', width: '200px' }} onChange={e => setSessCode(e.target.value)} /><br /><br />
                    </form>
                    {sessCode.length === 5 ?
                        <button onClick={sessionGo} style={{ textDecoration: 'none', backgroundColor: 'rgb(120, 63, 142)', color: 'white', padding: '15px 30px', fontSize: '14px', borderRadius: '5px', display: 'inline-block', fontWeight: 'bold' }} >
                            Next
                        </button>
                        :
                        <span></span>
                    }

                    <div style={{ color: 'rgb(120, 63, 142)', marginTop: '20px', display: 'block', textAlign: 'center', fontSize: '12px', width: '90%', maxWidth: "800px", padding: '10px' }}>
                        <strong>Where is my session code?</strong>
                        <div style={{ textAlign: 'left' }}>
                            <ol>
                                <li>Install and run the <a href="https://www.practiceloop.com/install" target="_blank" rel="noopener noreferrer">PracticeLoop App</a>.</li>
                                <li>Follow the set up instructions to get your session code.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: '12px', marginTop: '20px' }}>
                <a href="https://www.practiceloop.com/setup.html" target="_blank" rel="noopener noreferrer">Setup guide</a>&nbsp;|&nbsp;
                    <a href="https://www.practiceloop.com/privacy.html" target="_blank" rel="noopener noreferrer">Privacy policy</a>&nbsp;|&nbsp;
                    <a href="https://www.practiceloop.com/terms.html" target="_blank" rel="noopener noreferrer">Terms of service</a>
            </div>

        </div>
    )
}

export default EnterSessionCode;