import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import rootReducer from './reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import { connectWebsocketSaga, getIPAddressSaga, refreshConnectionSaga, refreshIpAddressSaga, getIdTokenSaga, refreshIdTokenSaga, startPollingSaga } from './sagas/connectionSagas'
import { getSessionFromCode } from './sagas/startUpSagas'
import { savePracticeView, loadPracticeView } from './sagas/practiceViewSaga'
import { saveCheckpoint, fetchLibrary } from './sagas/checkpointLibrarySaga'
import { telemetryService } from './services/telemetryService'
import { SubscriptionType, StartUpState } from './constants';

const savedDelay = localStorage.getItem('delay');
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer,
    {
        practiceView: {
            lhs: { type: "realtime" },
            rhs: { type: "delay" },
            checkpoints: {},
            isFlipped: false,
        },
        checkpointLibrary: {
            items: [],
            isLoading: false
        },
        user: {
            token: "",
            needsLogin: true
        },
        startUp: {
            sessionCode: "",
            sessionInfo: null,
            state: StartUpState.Init,
            subscription: { type: SubscriptionType.Undetermined, expiry : null }
        },
        brand: {},
        image: { fps: 20, delay: savedDelay ?? 4, queue: [], analysisBuffer: [], playbackMode: 'play', connectionState: 'trying' }
    },
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));

sagaMiddleware.run(getIdTokenSaga);
sagaMiddleware.run(refreshIdTokenSaga);
sagaMiddleware.run(getIPAddressSaga);
sagaMiddleware.run(refreshIpAddressSaga);
sagaMiddleware.run(refreshConnectionSaga);
sagaMiddleware.run(connectWebsocketSaga);
sagaMiddleware.run(savePracticeView);
sagaMiddleware.run(loadPracticeView);
sagaMiddleware.run(saveCheckpoint);
sagaMiddleware.run(fetchLibrary);
sagaMiddleware.run(startPollingSaga);

sagaMiddleware.run(getSessionFromCode);

telemetryService.trackEvent("Loaded application");

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();