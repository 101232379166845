import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { shop } from '../environment';

import ReactModal from 'react-modal';
import { connect } from 'react-redux';

import { telemetryService } from '../services/telemetryService';

const dialogStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px'
    },
    overlay: {zIndex: 1000}
};

class UpgradeToProButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCheckpointModal: false
        };

        this.openCheckpointModal = this.openCheckpointModal.bind(this);
        this.closeCheckpointModal = this.closeCheckpointModal.bind(this);

    }

    openCheckpointModal() {
        // Track that upgrade button was clicked
        telemetryService.trackMilestone("Upgrade button clicked");
        window.gtag('event', 'select_promotion', { creative_slot : `basic_user_${this.props.location}`, creative_name : `Upgrade from ${this.props.location}`, items : [{ item_name : '7 day trial'}] });

        this.setState({ showCheckpointModal: true });
    }

    closeCheckpointModal() {
        telemetryService.trackMilestone("Upgrade dialog closed");
        this.setState({showCheckpointModal :false });
    }

    render() {
        telemetryService.trackMilestone(`View promotion for: ${this.props.location}`);
        window.gtag('event', 'view_promotion', { creative_slot : `basic_user_${this.props.location}`, creative_name : `Upgrade from ${this.props.location}`, items : [{ item_name : '7 day trial'}] });

        return (
            <div>
                <button className="premium-button" onClick={this.openCheckpointModal}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>&nbsp;&nbsp;Try for 7 days</button><br/>
                <ReactModal
                    isOpen={this.state.showCheckpointModal}
                    contentLabel="Minimal Modal Example"
                    style={dialogStyle}
                    onRequestClose={this.closeCheckpointModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <iframe title="PracticeLoop Viewer Shop" src={`${shop.uri}/viewer-shop?t=${this.props.token}`} style={{width: '100%', height: '100%', border: 'none'}}></iframe>
                </ReactModal>

            </div>
        );
    }
}

const mapStateToProps = (state = {}) => {
    return {
      token: state.user.token
    }
  }

export default connect(mapStateToProps)(UpgradeToProButton);