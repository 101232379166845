import {telemetryService} from '../services/telemetryService';

const axios = require('axios');

export function fetchBrand() {
    return function (dispatch) {
        telemetryService.trackEvent("FIND_SUBDOMAIN", {host: window.location.host});
        let subdomain = window.location.host.split('.')[0];

        return axios.get(`/brands/viewer.json`).then(({ data }) => {
            dispatch(setBrand(data));
          })
          .catch(err => {
            telemetryService.trackEvent("FIND_SUBDOMAIN_FAIL", {host: window.location.host});
          })
    }
}

function setBrand(brand) {
    return {
        type: "SET_BRAND",
        brand
    }
}