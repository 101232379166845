import React from 'react';
import FrameViewer from './frameViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

const MODE_PLAY = "play";
const MODE_PAUSE = "pause";

// Props
// max - maximum value of the slider
// onModeChange
// onValueChange
class DelayPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.image.playbackMode,
      value: this.props.max,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });

    if (this.state.mode === 'play') {
      this.handlePause();
    }

    this.props.onChangeFrame(event.target.value);
  }

  handlePlay() {
    this.setState({ mode: MODE_PLAY });
    this.props.onChangeMode(MODE_PLAY);
  }

  handlePause() {
    this.setState({ mode: MODE_PAUSE });
    this.setState({ value: this.props.max })
    this.props.onChangeMode(MODE_PAUSE);
  }

  getImage(image) {
    if (this.props.image.devmode) {
      return '/placeholder.png';
    }

    return image;
  }

  getDelayedTitle() {
    if (this.state.mode === MODE_PLAY) {
      return "Delayed stream";
    }

    return "Paused for analysis";
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <FrameViewer title={this.getDelayedTitle()} imagePath={this.getImage(this.props.image.delayed)} mode="big"></FrameViewer>
        {((this.props.image.delayed || this.props.image.devmode)) ?  // Only show slider if there is a delayed image
          <div className="controlPanel slidecontainer">
            <div style={{ position: "relative", padding: "5px 0px 3px 0px" }}>

              {this.state.mode === MODE_PLAY ?
                <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <FontAwesomeIcon className="menuIcon" icon={faPause} onClick={this.handlePause} />
                  <div style={{ fontSize: "10px", color: "#333333", marginLeft: "auto", marginRight: "auto", paddingTop: "3px" }}>Pause and analyse</div>
                </div>
                :
                <div className="playWrapper" style={{display: "flex", textAlign: "left"}}>
                  <FontAwesomeIcon className="menuIcon" style={{ marginLeft: "8px", width: "40px"}} icon={faPlay} onClick={this.handlePlay} />
                  <input type="range" id="framenum" name="framenum" min="1" max={this.props.max} value={this.state.value} onChange={this.handleChange} className="slider" ></input>
                </div>

              }
            </div>
          </div>
          :
          <div className="delayLoaderWrapper">
            <div className="delay-loader"></div>
            <div style={{ padding: '20px 0 0 10px' }}>Waiting for delayed stream</div>
          </div>
        }
      </div>
    )
  }
}

export default DelayPanel;