import { Actions } from "../constants";

const user = (state = {}, action) => {
  switch (action.type) {
    case Actions.Connection.GetIdToken:
      let newState = Object.assign({}, state);
      newState.needsLogin = false;
      return newState;

    case Actions.Connection.SetIdToken:
      let userWithToken = Object.assign({}, state);
      userWithToken.token = action.token;
      userWithToken.needsLogin = false;
      return userWithToken;

    case Actions.Connection.GetIdTokenFailed:
      let stateWithFailure = Object.assign({}, state);
      stateWithFailure.needsLogin = true;
      return stateWithFailure;

    case Actions.Connection.LoggedOut:
      let stateLoggedOut = Object.assign({}, state);
      stateLoggedOut.token = "";
      stateLoggedOut.needsLogin = true;
      return stateLoggedOut;
    default:
      return state
  }
}

export default user
