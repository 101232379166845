import { telemetryService } from '../services/telemetryService';
import { Actions } from "../constants"

/* image state
realtime = the image to display in the real time panel
delayed = the image to display in the delayed panel - could be the live delay or a static image
queue = the array of images coming from the app
analysisBuffer = the last x seconds of images from the queue, used for pause mode to go back through time
analysisSet = a copy of the analysisBuffer at the point of time of entering "pause" mode
fps = how many fps are coming from the app
delay = how long to delay for in seconds
currentFrame = used in "pause" mode to know which frame to show
playbackMode = "play" or "pause" depending on the mode 
*/

const bufferTimeSecsMultiplier = 3;
const devmode = process.env.NODE_ENV !== 'production' && false; // Set to true to see images without connecting a phone

const config = (state = {}, action) => {
    switch (action.type) {
        case 'WEB_SOCKET_ON_MESSAGE':
            let realtimePointer = 0;

            // Set the new image as the realtime image
            if (!action.message) {
                return state;
            }

            let newState = Object.assign({}, state);

            let url = action.message;
            newState.realtime = url;

            if(state.pointer){
                realtimePointer = state.pointer;
            }

            let frameCount = (newState.fps * newState.delay);

            realtimePointer = realtimePointer % frameCount;

            newState.pointer = realtimePointer + 1;

            // Add the new image to the queue
            //newState.queue.push(newState.realtime);
            newState.queue[realtimePointer] = url;

            // If we have enough images to fill the desired delay period
            // Set the delayed image from the front of the queue and remove that image
            if (newState.queue.length >= frameCount) {
                //var delayImage = newState.queue.shift();
                var delayImage = newState.queue[(realtimePointer + 1) % frameCount];

                // You get a few seconds of analysis
                newState.analysisBuffer.push(delayImage);

                // Remove items from the analysis buffer once the buffer is exceeded
                if (newState.analysisBuffer.length > ((newState.fps * 3) +  (newState.fps * 2 * newState.delay))) {
                    newState.analysisBuffer.shift();
                }

                if (newState.playbackMode === "play") {
                    newState.delayed = delayImage;
                }
            }

            return newState;
        //TODO: Separate websocket listening and image handling
        case 'CHANGE_FRAME':
            state.currentFrame = action.framenum;

            if (state.analysisSet && state.analysisSet.length > (state.currentFrame)) {
                state.delayed = state.analysisSet[state.currentFrame - 1];
            }

            return Object.assign({}, state);
        case 'CHANGE_PLAYBACK_MODE':
            telemetryService.trackEvent("CHANGE_PLAYBACK_MODE", { mode: action.mode });
            if (action.mode === "pause") {
                // analysisBuffer is continually populated as more frames come in
                // so when entering "pause" mode we need to take a copy at that time
                state.analysisSet = Object.assign([], state.analysisBuffer);
            }
            else {
                // When returning to "play" mode we move the frame to the most recent and clear the analysisSet
                state.currentFrame = ((state.fps * 3) + (state.fps * 2 * state.delay));

                if (state.analysisSet) {
                    state.analysisSet.length = 0;
                }
            }

            state.playbackMode = action.mode;
            return Object.assign({}, state);
        case 'CONNECTION_CHANGED_STATE':
            telemetryService.trackEvent("CONNECTION_CHANGED_STATE", { connectionState: action.connectionState });
            state.connectionState = action.connectionState;

            state.devmode = devmode;
            if (devmode) {
                state.connectionState = "connected";
            }

            return Object.assign({}, state);

        case 'WEBSOCKET/CLOSED':
            telemetryService.trackEvent("CONNECTION_CHANGED_STATE", { connectionState: "failed" });
            state.connectionState = "failed";

            state.devmode = devmode;
            if (devmode) {
                state.connectionState = "connected";
            }

            return Object.assign({}, state);
        case 'SET_DELAY_PERIOD':
            let setDelayPeriodState = Object.assign({}, state);
            setDelayPeriodState.delay = action.delay;
            localStorage.setItem('delay', action.delay);
            return setDelayPeriodState;
        // Cannot get IP address for phone
        case Actions.Connection.GetTetherIpFailed:
            state.connectionState = "get-ip-failed";

            state.devmode = devmode;
            if (devmode) {
                state.connectionState = "connected";
            }

            return Object.assign({}, state);
        default:
            return state;
    }
}

export default config
