import { connect } from 'react-redux'
import { saveCheckpoint, fetchLibrary } from '../actions/checkpointLibrary'
import CheckPoint from '../components/imageView/checkpoint'

const mapDispatchToProps = dispatch => {
  return {
      onSaveCheckpoint : (checkpoint) => {
        dispatch(saveCheckpoint(checkpoint))
      },
      onFetchLibrary: () => {
        dispatch(fetchLibrary())
      }
  }
}

const mapStateToProps = (state = {})  => {
  return {
    checkpointLibrary: state.checkpointLibrary,
    token: state.user.token
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckPoint)
