export const saveCheckpoint = (checkpoint) => ({
    type: "CHECKPOINTLIBRARY/SAVE_CHECKPOINT",
    checkpoint
})

export const setLibrary = (items) => ({
    type: "CHECKPOINTLIBRARY/SET_LIBRARY",
    items
})

export const fetchLibrary = () => ({
    type: "CHECKPOINTLIBRARY/FETCH_LIBRARY"
})