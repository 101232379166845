import React from 'react';

const imgStyle = {
  width: '100%',
  height: 'auto'
}

const headingStyle = {
  position: 'absolute',
  padding: '10px',
  color: '#FFFFFF',
  textShadow: '2px 2px 2px #333333',
  fontSize: '12px'
}

const smallDimens = {
  width: '100%',
  height: 'auto'
}

const tinyDimens = {
}

// props = imagePath, id 
function FrameViewer(props) {
  let imgStyleWithDimens = imgStyle;
  //let divStyleWithDimens = noImageDivStyle;

  switch (props.mode.toUpperCase()) {
    case "SMALL":
      imgStyleWithDimens = { ...imgStyle, ...smallDimens }
      //divStyleWithDimens = { ...noImageDivStyle, ...smallDimens }
      break;
    case "TINY":
      imgStyleWithDimens = { ...imgStyle, ...tinyDimens }
      //divStyleWithDimens = { ...noImageDivStyle, ...tinyDimens }
      break;
    default:
      break;
  }


  // If there is no imagePath then use viewer_placeholder.png (logo)
  // If the imagePath is placeholder.png then use that (dev mode)
  // Otherwise, there is an imagePath that isn't dev mode, so make an object url
  let image = props.imagePath ? (props.imagePath === "/placeholder.png" ? props.imagePath : URL.createObjectURL(props.imagePath)) : "/viewer_placeholder.png";

  return (
    <div style={{height: '100%'}}>
      {props.mode.toUpperCase() !== "TINY" ?
      <div>
      <div style={headingStyle}>{props.title}</div>
      <img src={image} className="cameraImage" style={imgStyleWithDimens} alt="Preview" onLoad={() => {URL.revokeObjectURL(image)}}></img>
      </div>
      :
      <img src={image} className="cameraImage" style={imgStyleWithDimens} alt="Preview" onLoad={() => {URL.revokeObjectURL(image)}}></img>
  }
    </div>
  );
}

export default FrameViewer;