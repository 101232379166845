export const enterRealtimeMode = () => ({
    type: "ENTER_REALTIME_MODE"
})

export const enterYoutubeMode = () => ({
    type: "ENTER_YOUTUBE_MODE"
})

export const enterSingleCheckpointMode = () => ({
    type: "ENTER_SINGLE_CHECKPOINT_MODE"
})

export const enterDoubleCheckpointMode = () => ({
    type: "ENTER_DOUBLE_CHECKPOINT_MODE"
})

export const setCheckpoint = (checkpointNum, image, name) => ({
    type: "SET_CHECKPOINT",
    checkpointNum,
    image,
    name
})

export const setPracticeView = (view) => ({
    type: "SET_PRACTICE_VIEW",
    view
})

export const toggleMainView = () => ({
    type: "TOGGLE_MAIN_VIEW"
})

export const toggleFlippedView= () => ({
    type: "TOGGLE_FLIPPED_VIEW"
})