const checkpoints = (state = {}, action) => {
    switch (action.type) {
        case 'CHECKPOINTLIBRARY/SET_LIBRARY':
            let newState = Object.assign({}, state);
            newState.items = action.items;
            newState.isLoading = false;
            return newState;
        case 'CHECKPOINTLIBRARY/FETCH_LIBRARY':
            let fetchState = Object.assign({}, state);
            fetchState.isLoading = true;
            return fetchState;
        default:
            return state
    }
  }
  
  export default checkpoints
  