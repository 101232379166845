const practiceView = (state = [], action) => {
  switch (action.type) {
    case 'ADD_SWING_THOUGHT':
      return [
        ...state,
        {
          id: action.id,
          text: action.text
        }
      ]
    case 'UPDATE_SWING_THOUGHT':
      return state.map(swingThought =>
        swingThought.id === action.id ? { ...swingThought, text: action.text } : swingThought
      )
    case 'REMOVE_SWING_THOUGHT':
      return state.filter(swingThought => swingThought.id !== action.id)
    case 'SET_DATA':
      return action.initialState.swingThoughts;

    case 'ENTER_REALTIME_MODE':
      var freeState = Object.assign({}, state);
      freeState.lhs = { type: "realtime" };
      freeState.rhs = { type: "delay" };
      return freeState;

    case 'ENTER_YOUTUBE_MODE':
      var youtubeState = Object.assign({}, state);
      youtubeState.lhs = { type: "youtube" };
      youtubeState.rhs = { type: "delay" };
      return youtubeState;
      
    case 'ENTER_SINGLE_CHECKPOINT_MODE':
      var checkpointState = Object.assign({}, state);
      checkpointState.lhs = { type: "single_checkpoint" };
      return checkpointState;

    case 'ENTER_DOUBLE_CHECKPOINT_MODE':
      var doubleCheckpointState = Object.assign({}, state);
      doubleCheckpointState.lhs = { type: "double_checkpoint" };
      return doubleCheckpointState;

    case 'SET_CHECKPOINT':
      var checkpointNum = action.checkpointNum;
      var newState = Object.assign({}, state);

      if (action.image || action.name === "New checkpoint") {
        newState.checkpoints[`checkpoint${checkpointNum}`] = { ...newState.checkpoints[`checkpoint${checkpointNum}`], image: action.image }
      }

      if (action.name) {
        newState.checkpoints[`checkpoint${checkpointNum}`] = { ...newState.checkpoints[`checkpoint${checkpointNum}`], name: action.name }
      }

      return newState;

    case 'SET_PRACTICE_VIEW':
      var loadViewState = Object.assign({}, state);
      loadViewState = action.view;
      return loadViewState;

    case 'TOGGLE_MAIN_VIEW':
      var toggleState = Object.assign({}, state);

      toggleState.rhs.type = toggleState.rhs.type === "realtime" ?  "delay" : "realtime";

      return toggleState;

      case 'TOGGLE_FLIPPED_VIEW':
        var flipViewState= Object.assign({}, state);
        flipViewState.isFlipped = !flipViewState.isFlipped;
        return flipViewState;

    default:
      return state
  }
}

export default practiceView
