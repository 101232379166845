import { Actions, SubscriptionType } from "../constants";


const startUp = (state = {}, action) => {
  switch (action.type) {
    case Actions.StartUp.SetSessionCode:
      let scState = Object.assign({}, state);
      scState.sessionCode = action.code;
      scState.state = action.state;
      return scState;
    case Actions.StartUp.SetSession:
      let sessState = Object.assign({}, state);
      sessState.sessionInfo = action.sessionInfo;
      sessState.subscription.type = getSubscription(action.subscriptions);
      sessState.state = action.state;
      return sessState;
    case Actions.StartUp.SetSessionFailed:
        let failState = Object.assign({}, state);
        failState.sessionInfo = null;
        failState.subscription.type = null;
        failState.state = action.state;
        return failState;
    default:
      return state;
  }
}

const getSubscription = (subs) => {
  if(subs && (subs.includes("premium_monthly") || subs.includes("premium") || subs.includes("premium_monthly_plus") || subs.includes("premium_yearly_plus"))){
    return SubscriptionType.Pro;
  }

  return SubscriptionType.Basic;
}

export default startUp
