
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLock, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

function UpgradeBanner(){
    return (
        <div className="promo" style={{ zIndex: '10', position: 'absolute', textAlign: 'center', border: '10px solid yellowgreen', backgroundColor: "#FFFFFF", width: '70%', minWidth:'300px', marginTop:'20px', opacity: '0.90' }}>
        <h2 style={{ color: 'rgb(120, 63, 142)' }}><FontAwesomeIcon icon={faLock}></FontAwesomeIcon> Feature locked</h2>
        <p style={{fontWeight: 'bold'}}>Try the pro subscription free for 7 days!</p>
        <div>
            <table className="compare-table">
                <tbody>
                    <tr>
                        <th style={{ width: '50%' }}>Features</th>
                        <th>Basic</th>
                        <th>Pro</th>
                    </tr>
                    <tr>
                        <td>Realtime video stream</td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>Delayed video stream</td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>Pause delayed video</td>
                        <td><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>Checkpoints</td>
                        <td><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p><strong>Improve faster</strong> with PracticeLoop Pro.</p>
        <h2 style={{ color: 'white', background: 'rgb(120, 63, 142)', margin: '5px', padding: '10px' }}>Return to the app to unlock pro features</h2>
      </div>
    );
}

export default UpgradeBanner;