import { connect } from 'react-redux'
import { changeFrame, changePlaybackMode } from '../actions/imageActions'
import DelayPanel from '../components/imageView/delayPanel'

const mapDispatchToProps = dispatch => {
  return {
      onChangeFrame: framenum => {
        dispatch(changeFrame(framenum))
      }, 
      onChangeMode : (mode) => {
        dispatch(changePlaybackMode(mode))
      }
  }
}

const mapStateToProps = (state = {}, ownProps)  => {
  return {
      image: state.image || {},
      max: ownProps.max
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelayPanel)
