import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faSignInAlt, faRedo } from '@fortawesome/free-solid-svg-icons'

import './App.css';
import Login from './components/login'
import EnterSessionCode from './components/enterSessionCode'
import ImagePanelContainer from './containers/ImagePanelContainer';


import { fetchBrand } from './actions/brand';
import { getIdToken, refreshIdToken } from './actions/connectionActions'
import { setSessionCode } from './actions/startUpActions'

import { auth0 } from './environment';

import Cookies from 'js-cookie';
import { telemetryService } from './services/telemetryService'
import StartUp from './components/startUp';
import LogoText from './components/logoText';
import { StartUpState } from './constants';
import {toggleFlippedView} from "./actions/practiceView";

const contentStyle = {
  height: '100%'
}

const contentStyleFlipped = {
  overflow: 'hidden',
  transformOrigin: 'top left',
  transform: 'translate(100vw) rotate(90deg)',
  width: '100vh',
  height: '100vw'
}

class App extends React.Component {
  componentDidMount() {
    this.props.fetchBrand();
    this.checkLogin();
  }

  callToActionClick(url) {
    window.location.href = url;
  }

  getAbsoluteImage(imageName) {
    return `/${imageName}`;
  }

  checkLogin() {
    const params = new URLSearchParams(window.location.search);
    const isAuth0Redirect = params.has("code") && params.has("state");
    const isPaymentCancelledRedirect = window.location.pathname === "/subscription/canceled";
    const refreshToken = Cookies.get("rt");
    const isSessionCode = params.has("go");
    const isSessionCodeCookie = Cookies.get("sc");

    if (isAuth0Redirect) {
      this.props.getIdToken(params.get("code"));
      window.history.replaceState({}, document.title, "/");
      telemetryService.trackMilestone("User logged in");
      window.gtag('event', 'login', { method: 'auth0' });
    }
    else if (refreshToken) {
      this.props.refreshIdToken(refreshToken);
      window.gtag('event', 'login', { method: 'refresh_token' });

      if (isPaymentCancelledRedirect) {
        telemetryService.trackMilestone("Payment cancelled");
        window.history.replaceState({}, document.title, "/");
      }
    }
    if (isSessionCode) {
      Cookies.set("sc", params.get("go"));
      this.props.setSessionCode(params.get("go"));
      telemetryService.trackMilestone("Session code init");
    }
    else if(isSessionCodeCookie) {
      this.props.setSessionCode(Cookies.get("sc"));
      telemetryService.trackMilestone("Session code init cookie");
    }
  }

  logout() {
    telemetryService.trackMilestone("User logged out");
    Cookies.remove("rt");
    window.location.href = `${auth0.host}/v2/logout?client_id=${auth0.clientId}&returnTo=${auth0.redirect}`;
  }

  clearSessionCode() {
    Cookies.remove("sc");
    window.location.href = '/';
  }

  login() {
    telemetryService.trackMilestone("User login menu click");
    window.location.href = '/login';
  }

  render() {
    if (!this.props.brand || (Object.keys(this.props.brand).length === 0 && this.props.brand.constructor === Object)) {
      return (<div></div>)
    }

    let cta = <span></span>
    let signout = <span></span>
    let sessionCode = <span></span>

    if (this.props.brand.callToAction) {
      cta = <button className="call-to-action" style={this.props.brand.styles.callToAction} onClick={() => { this.callToActionClick(this.props.brand.callToAction.redirect) }}>{this.props.brand.callToAction.text}</button>
    }

    if (this.props.startUp.sessionCode !== "") {
      sessionCode = <button className="call-to-action"  onClick={this.clearSessionCode}>{this.props.startUp.sessionCode}</button>

    }


    if (!this.props.user.needsLogin) {
      signout = <button className="logout-button" style={this.props.brand.styles.logoutButton} onClick={this.logout} alt="Log out"><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon><p>Log out</p></button>
    }
    else {
      signout = <button className="logout-button" style={this.props.brand.styles.logoutButton} onClick={this.login} alt="Log in"><FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon><p>Log in</p></button>
    }

    return (
      <Router>
        <div style={this.props.flippedView ? contentStyleFlipped : contentStyle}>
          <div className="heading" style={this.props.brand.styles.heading}>
            {cta}
            {signout}
            {sessionCode}
              <button className={this.props.flippedView ? "logout-button" : "logout-button show-on-landscape-only"} style={{marginRight: '30px'}} onClick={() => this.props.toggleFlippedView() } ><FontAwesomeIcon icon={faRedo} style={{marginTop: '4px', fontSize: '14px'}}></FontAwesomeIcon><p>TV Flip</p></button>
            <div>
              <LogoText style={{fontSize: "16px"}} light="true" /> <span style={{fontSize: "10px"}}>viewer</span>
            </div>
          </div>
          <Switch>
            <Route path="/login">
              <Login sessionCode={this.props.startUp.sessionCode}></Login>
            </Route>
            <Route path="/">
            {this.props.startUp.state !== StartUpState.SessionLookupComplete ?
            this.props.startUp.sessionCode !== "" ?
                <StartUp startUp={this.props.startUp}></StartUp>
              :
              <EnterSessionCode></EnterSessionCode>
            :
                <ImagePanelContainer></ImagePanelContainer>
          }
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state = {}) => {
  return {
    connectionState: state.image ? state.image.connectionState : "trying",
    brand: state.brand,
    user: state.user,
    startUp: state.startUp,
    flippedView: state.practiceView.isFlipped
  }
}

export default connect(
  mapStateToProps,
  {
    fetchBrand,
    getIdToken,
    refreshIdToken,
    setSessionCode,
    toggleFlippedView
  }
)(App);
