  export const imageReceived = message => ({
    type: 'WEB_SOCKET_ON_MESSAGE',
    message: message
  })

  export const changeFrame = framenum => ({
    type: 'CHANGE_FRAME',
    framenum
  })

  export const changePlaybackMode = mode => ({
    type: 'CHANGE_PLAYBACK_MODE',
    mode
  })

  /* connected, trying, failed */
  export const connectionStateChanged = connectionState => ({
    type: 'CONNECTION_CHANGED_STATE',
    connectionState
  })

  export const stopPollingImages = () => ({
    type: 'STOP_POLLING_IMAGES'
  })

  /* The time the delay takes to show */
  export const setDelayPeriod = delay => ({
    type: 'SET_DELAY_PERIOD',
    delay
  })


  