import React from 'react';
import FrameViewer from './frameViewer';

// Props
// max - maximum value of the slider
// onModeChange
// onValueChange
class RealTimePanel extends React.Component {
  getImage(image) {
    if (this.props.image.devmode) {
      return '/placeholder.png';
    }

    return image;
  }

  render() {
    let image = this.getImage(this.props.image.realtime);

    return (
      <FrameViewer title={this.props.size === "TINY" ? "Realtime" : "Realtime stream"} imagePath={image} mode={this.props.size}></FrameViewer>
    )
  }
}

export default RealTimePanel;