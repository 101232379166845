const SubscriptionType = Object.freeze(
    {
        Basic: "basic",
        ProTrial: "pro_trial",
        Pro: "pro",
        Undetermined: "undetermined"
    }
)

const Claims = Object.freeze(
    {
        SubscriptionExpiry: "https://viewer.scratchtime.golf/2021/05/identity/claims/subscription_expiry",
        CreatedAt:"https://viewer.scratchtime.golf/2021/05/identity/claims/created_at"
    }
)

const Roles = Object.freeze(
    {
        Pro : "Pro Subscription"
    }
)

const Actions = Object.freeze(
    {
        Connection:
        {
            GetIdToken: "CONNECTION/GET_ID_TOKEN",
            RefreshIdToken: "CONNECTION/REFRESH_ID_TOKEN",
            SetIdToken: "CONNECTION/SET_ID_TOKEN",
            GetIdTokenFailed: "CONNECTION/GET_ID_TOKEN_FAILED",
            GetTetherIp: "CONNECTION/GET_TETHER_IP",
            SetTetherIp: "CONNECTION/SET_TETHER_IP",
            GetTetherIpFailed: "CONNECTION/GET_TETHER_IP_FAILED",
            LoggedOut: "CONNECTION/LOGGED_OUT"
        },
        Websocket: {
            Closed: "WEBSOCKET/CLOSED"
        },
        StartUp:{
            SetSessionCode: "START_UP/SET_SESSION_CODE",
            SetSession: "START_UP/SET_SESSION",
            SetSessionFailed:"START_UP/SET_SESSION_FAILED"
        }
    }
)

const StartUpState = Object.freeze(
    {
        Init: "INIT",
        TrySessionLookup: "TRY_SESSION_LOOKUP",
        SessionLookupFailed: "SESSION_LOOKUP_FAILED",
        SessionLookupStale: "SESSION_LOOKUP_STALE",
        SessionLookupComplete: "SESSION_LOOKUP_COMPLETE",
    }
)


export { SubscriptionType, Actions, Claims, Roles, StartUpState }