import { Actions, StartUpState } from "../constants"

export const setSessionCode = (code) => ({
    type: Actions.StartUp.SetSessionCode,
    state: StartUpState.TrySessionLookup,
    code
})

// If the session was made more than an hour ago, consider it stale
// This relies on clocks being in sync so will need to make sure it doesn't cause too much trouble in the real world
export const setSession = (session) => ({
    type: Actions.StartUp.SetSession,
    state: session && session.app_ping_time && session.app_ping_time < ((Date.now() / 1000) - 3600) ? StartUpState.SessionLookupStale :  StartUpState.SessionLookupComplete,
    subscriptions: session && session.subscriptions,
    session
})

export const setSessionFailed = () => ({
    type: Actions.StartUp.SetSessionFailed,
    state: StartUpState.SessionLookupFailed
})