import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTimesCircle, faCheckCircle, faLock, faEye } from '@fortawesome/free-solid-svg-icons'

import SetupHelp from '../setupHelp'
import UpgradeToProButton from '../upgradeToProButton'
import UpgradeBanner from '../upgradeBanner'
import ImagePanelWrapper from '../imageView/imagePanelWrapper'
import DelayPanelContainer from '../../containers/DelayPanelContainer'
import RealTimePanelContainer from '../../containers/RealTimePanelContainer'
import CheckPointContainer from '../../containers/CheckpointContainer'
import { SubscriptionType } from '../../constants';

const modeButtonWrapper = {
  flex: '1 0 0',
  whiteSpace: 'nowrap',
  width: '25%',
  maxWidth: '70px'
}

let youtubeInput = React.createRef();

// Props
// max - maximum value of the slider
// onModeChange
// onValueChange
class ImagePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = { displayMaxWidth: "100%", youtube: "", showMiniRealtime: true };

    this.setSingleCheckpointMode = this.setSingleCheckpointMode.bind(this);
    this.setDoubleCheckpointMode = this.setDoubleCheckpointMode.bind(this);
    this.setFreePractice = this.setFreePractice.bind(this);
    this.setYoutube = this.setYoutube.bind(this);
    this.onSetCheckpoint = this.onSetCheckpoint.bind(this);
    this.onSetCurrentImage = this.onSetCurrentImage.bind(this);
    this.openYoutube = this.openYoutube.bind(this);
    this.toggleMainView = this.toggleMainView.bind(this);
    this.toggleMiniRealtime = this.toggleMiniRealtime.bind(this);
    this.setDelayPeriod = this.setDelayPeriod.bind(this);
  }

  getImage(image) {
    if (this.props.image.devmode) {
      return '/placeholder.png';
    }

    return image;
  }

  toggleMiniRealtime() {
    this.setState({showMiniRealtime : !this.state.showMiniRealtime});
  }

  toggleMainView() {
    this.props.onToggleMainView();
  }

  setSingleCheckpointMode() {
    this.props.onEnterSingleCheckpointMode();
  }

  setDoubleCheckpointMode() {
    this.props.onEnterDoubleCheckpointMode();
  }

  setFreePractice() {
    this.props.onEnterRealtimeMode();
  }

  setYoutube() {
    this.props.onEnterYoutubeMode();
  }

  onSetCurrentImage(checkpointNum) {
    var image = this.getImage(this.props.image.delayed);
    this.onSetCheckpoint(checkpointNum, image, undefined)
  }

  setDelayPeriod(event) {
    this.props.onSetDelayPeriod(event.target.value);
  }

  openYoutube() {
    var textInput = youtubeInput.current.value;

    if (textInput.indexOf('?') > 0) {
      textInput = textInput.split('?')[1];

      const params = new URLSearchParams(textInput);
      textInput = params.get("v");
    }

    this.setState({ youtube: `https://www.youtube.com/embed/${textInput}` });
  }

  async onSetCheckpoint(checkpointNum, image, name) {
    // Practice Views must have base64 image rather than blob: so if is in blob format we need to transform it
    if (image) {
      image = await getBase64(image);
    }

    this.props.onSetCheckpoint(checkpointNum, image, name);
  }

  render() {
    let lhs = this.props.practiceView.lhs;
    let greyedOut = {};
    let upgradeBanner = <span></span>

    let miniRealtimeView = <span></span>;

    // If we are loading a checkpoint but the user doesn't have a pro subscription, then we want to restrict the feature and show some marketing
    if (this.props.subscription.type !== SubscriptionType.Pro && this.props.subscription.type !== SubscriptionType.ProTrial) {
      greyedOut = { color: "#666666" }

      if (lhs.type.includes("_checkpoint") || lhs.type === "youtube" || this.props.image.playbackMode === "pause") {
        upgradeBanner = <UpgradeBanner />
      }
    }

    if (!this.props.image || this.props.image.connectionState !== "connected") {
      return (
        <SetupHelp brand={this.props.brand} connectionState={this.props.image.connectionState} />)
    }

    let miniRealtimeWidth = this.state.showMiniRealtime ? "150px" : "20px";

    if (this.props.practiceView.rhs.type === "delay"){
      miniRealtimeView = <div className={this.props.practiceView.isFlipped ? "show-on-portrait-only-flipped" : "show-on-portrait-only"} style={{ position: 'absolute', bottom: '70px', left: '1%', width: '30%',
            maxWidth: miniRealtimeWidth,
            height: 'auto',
            border: '1px solid #CCCCCC',
              boxShadow: '2px 2px 4px #CCCCCC' }}
              onClick={this.toggleMiniRealtime}>
            <RealTimePanelContainer size="TINY" ></RealTimePanelContainer>
          </div>
    }

    return (
      <ImagePanelWrapper>
        <div className={this.props.practiceView.isFlipped ? "menuPanel show-on-landscape-only-flipped" : "menuPanel show-on-landscape-only"}>
          <LoadPanel view={lhs}
            checkpoints={this.props.practiceView.checkpoints}
            playbackMode={this.props.image.playbackMode}
            onSetCurrentImage={this.onSetCurrentImage}
            onSetCheckpoint={this.onSetCheckpoint}
            openYoutube={this.openYoutube}
            parentState={this.state} />
          <div className="controlPanel" style={{position: 'absolute', bottom: '3px', left: '0px', backgroundColor: 'rgba(255, 255, 255, 0.5)', width: '100%', padding: '0px' }}>
            <div style={{ display: 'flex', marginTop: '5px', justifyContent: "center" }}>
              <div style={modeButtonWrapper}><button onClick={this.setFreePractice} className="menuIcon"><FontAwesomeIcon icon={faEye} /><div style={{ fontSize: '10px' }}>Realtime</div></button></div>
              <div style={modeButtonWrapper}><button onClick={this.setSingleCheckpointMode} className="menuIcon" style={{ greyedOut }}><FontAwesomeIcon icon={faImage} /><div style={{ fontSize: '10px' }}>Checkpoint</div></button></div>
            </div>
          </div>
        </div>
        <div className={this.props.practiceView.isFlipped ? "viewPanel-flipped" : "viewPanel"}>
          <div className="slider-small" style={{position: "absolute", right: "0px", color:"#EEEEEE", textShadow: '2px 2px 2px #333333', textAlign:"center", marginRight:"2%"}}>
          <div style={{fontSize: "12px"}} >Delay</div>
            <input type="range" orient="vertical" id="framenum" name="framenum" min="0" max={16} value={this.props.image.delay} onChange={this.setDelayPeriod} className="slider"></input>
          </div>

          <LoadPanel view={this.props.practiceView.rhs} frameCount={(this.props.image.fps * 3) + (this.props.image.delay * this.props.image.fps * 2)} />
          {miniRealtimeView}
          
        </div>
        {upgradeBanner}

      </ImagePanelWrapper>
    )
  }
}

const LoadPanel = props => {
  var layout = <div></div>
  switch (props.view.type) {
    case "delay":
      layout = <DelayPanelContainer max={props.frameCount}></DelayPanelContainer>
      break;
    case "realtime":
      layout = <RealTimePanelContainer></RealTimePanelContainer>
      break;
    case "youtube":
      layout = (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: 'auto', backgroundColor: '#eeeeee' }}>
          <img src="/ratio.png" style={{width: '50%', height: 'auto'}} alt="" />
          <img src="/ratio.png" style={{width: '50%', height: 'auto'}} alt="" />
          <div style={{width: '100%', position: 'absolute', fontSize:"10px"}}>
            {props.parentState.youtube === '' ?
              <div style={{ position: "relative"  }}>
                <div style={{ color: 'rgb(120, 63, 142)', paddingTop: '20%' }}><img src="./yt_logo_rgb_light.png" alt="Watch on YouTube" style={{ width: "30%", height: "auto" }}></img><br /><br />Paste YouTube lesson link below to watch.</div>
              </div>
              :
              <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                <iframe style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} src={props.parentState.youtube} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            }
            <div style={{ display: 'inline-block', width: '90%', position: 'relative', marginTop: '10px' }}>
              <input style={{ width: '80%', padding: '10px', border: '1px solid rgb(120, 63, 142)', borderRadius: '50px', paddingRight: '50px' }} type="text" ref={youtubeInput} placeholder="https://www.youtube.com/watch?v=2zX5bvANF48" onKeyUp={(e) => { if (e.keyCode === 13) { props.openYoutube(); } }} ></input>
              <button style={{ position: 'absolute', color: "white", left: 'calc(80% + 30px)', top: '4px', backgroundColor: "rgb(120, 63, 142)", padding: "7px", borderRadius: "50px" }} onClick={props.openYoutube}>Go</button>
            </div>
          </div>
          <div style={{ width: '50%', height: 'auto', backgroundColor: '#EEEEEE'}}>
            <img src="/logo_text.png" style={{ width: '33%', height: 'auto', marginLeft: '5%', marginTop: '30%' }} alt="PracticeLoop"></img>
          </div>
          <div style={{ width: '50%', top: '50%', left: '50%' }}>
            <RealTimePanelContainer size="SMALL" ></RealTimePanelContainer>
          </div>
        </div>)
      break;
    case "double_checkpoint":
      layout = (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: 'auto' }}>
          <div style={{ width: '50%', height: 'auto', marginBottom: '-3px', backgroundColor: '#eeeeee' }}>
            <CheckPointContainer checkpoint={props.checkpoints.checkpoint1}
              index="1"
              onSetCurrentImage={props.onSetCurrentImage}
              onSetCheckpoint={props.onSetCheckpoint}
              playbackMode={props.playbackMode}>
              Checkpoint slot 1
            </CheckPointContainer>
          </div>
          <div style={{ width: '50%', height: 'auto', marginBottom: '-3px', backgroundColor: '#eeeeee' }}>
            <CheckPointContainer checkpoint={props.checkpoints.checkpoint2}
              index="2"
              onSetCurrentImage={props.onSetCurrentImage}
              onSetCheckpoint={props.onSetCheckpoint}
              playbackMode={props.playbackMode}>
              Checkpoint slot 2
          </CheckPointContainer>
          </div>
          <div style={{ width: '50%', height: 'auto', backgroundColor: '#EEEEEE' }}>
            <img src="/logo_text.png" style={{ width: '33%', height: 'auto', marginLeft: '5%', marginTop: '30%' }} alt="PracticeLoop"></img>
          </div>
          <div style={{ width: '50%' }}>
            <RealTimePanelContainer size="SMALL" ></RealTimePanelContainer>
          </div>

        </div>)
      break;
    case "single_checkpoint":
      layout = (
        <div style={{ width: '100%', height: 'auto' }}>
          <CheckPointContainer size="big"
            checkpoint={props.checkpoints.checkpoint1}
            index="1"
            onSetCurrentImage={props.onSetCurrentImage}
            onSetCheckpoint={props.onSetCheckpoint}
            playbackMode={props.playbackMode}>
            Checkpoints
          </CheckPointContainer>
          <div className="show-on-landscape-only" style={{ position: 'absolute', bottom: '65px', left: '1%', width: '30%',
            maxWidth: '150px',
            height: 'auto',
            border: '1px solid #CCCCCC',
              boxShadow: '2px 2px 4px #CCCCCC' }}>
            <RealTimePanelContainer size="TINY" ></RealTimePanelContainer>
          </div>
        </div>
      )
      break;
    case "upgrade_to_pro_checkpoint":
      layout = (
        <div className="promo" style={{ width: '100%', height: '100%', backgroundColor: "#EEEEEE" }}>
          <div style={{ paddingTop: "5px", textAlign: "center" }}>
            <h1 style={{ color: 'rgb(120, 63, 142)', marginBottom: '0px' }}><FontAwesomeIcon icon={faLock}></FontAwesomeIcon>&nbsp;&nbsp;Checkpoints</h1>
            <p>Included in the <strong>Pro subscription</strong></p>
            <p>Compare yourself to important reference points.</p>
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', left: '27%', top: '27%', color: 'red', fontSize: '30px' }}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></div>
              <div style={{ position: 'absolute', left: '53%', top: '27%', color: 'green', fontSize: '30px' }}><FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon></div>
              <img src="/trackman.png" style={{ width: '50%', border: "1px solid #999999", boxShadow: '3px 3px 3px #aaaaaa' }} alt="Checkpoint sample"></img>
            </div>
            <div style={{ display: 'inline-block', width: '80%'}}>
              <p> <strong style={{ color: '#666666' }}>It's great</strong> for working with your coach.</p>
            </div>
            <p><strong>Improve faster</strong> with PracticeLoop Pro.</p><br />
            <UpgradeToProButton location="checkpoints">Start 7 day trial</UpgradeToProButton>
          </div>
        </div>
      )
      break;
    case "upgrade_to_pro_youtube":
      layout = (
        <div className="promo" style={{ width: '100%', height: '100%', backgroundColor: "#EEEEEE" }}>
          <div style={{ paddingTop: "5px", textAlign: "center" }}>
            <h1 style={{ color: 'rgb(120, 63, 142)', marginBottom: '0px' }}><FontAwesomeIcon icon={faLock}></FontAwesomeIcon>&nbsp;&nbsp;Inline YouTube lesson</h1>
            <p>Included in the <strong>Pro subscription</strong></p>
            <p>Watch a YouTube lesson along side your swing.</p>
            <div style={{ position: 'relative' }}>
              <img src="/youtube_example.png" style={{ width: '70%', border: "1px solid #999999", boxShadow: '3px 3px 3px #aaaaaa' }} alt="YouTube sample"></img>
            </div>
            <p className="tag-line">Make sure you <strong>copy</strong> the lesson properly.</p>
            <p><strong>Improve faster</strong> with PracticeLoop Pro.</p>
            <UpgradeToProButton location="youtube">Start 7 day trial</UpgradeToProButton>
          </div>
        </div>
      )
      break;
    default:
      layout = <div></div>
      break;
  }

  return layout;
}

async function getBase64(blobUrl) {
  let blob = blobUrl;

  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result); // data url!
    };

    reader.onerror = function () {
      reject();
    }

    reader.readAsDataURL(new Blob([blob]));
  })
}


export default ImagePanel;