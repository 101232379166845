import { Actions } from "../constants"

// During login, use the code supplied by Auth0 to request an ID token
export const getIdToken = (code) => ({
    type: Actions.Connection.GetIdToken,
    code
})

// Try to get an id token from a refresh token
export const refreshIdToken = (refreshToken) => ({
    type: Actions.Connection.RefreshIdToken,
    refreshToken
})

// Once the token has been returned, set the token in state
export const setIdToken = (token) => ({
    type: Actions.Connection.SetIdToken,
    token
})

// Failed to get the ID token
export const getIdTokenFailed = () => ({
    type: Actions.Connection.GetIdTokenFailed,
})

// Use the token to get the private IP address of the phone
export const getTetherIPAddress = (token) => ({
    type: Actions.Connection.GetTetherIp,
    token
})

// Once the IP address is known, call this action trigger the connection setup
export const setTetherIpAddress = (ipAddress, appVersion) => ({
    type: Actions.Connection.SetTetherIp,
    ipAddress,
    appVersion
})

// Failed to get the IP address of the phone
export const tetherIpAddressFailed = (error) => ({
    type: Actions.Connection.GetTetherIpFailed,
    error
})

// Web socket closed, either through failure of closing
export const webSocketClosed = () => ({
    type: Actions.Websocket.Closed
})

// User has chosen to log out
export const loggedOut = () => ({
    type: Actions.Connection.LoggedOut
})

