const brand = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BRAND':
            return action.brand;
        default:
            return state
    }
  }
  
  export default brand
  