import {ApplicationInsights} from '@microsoft/applicationinsights-web';

class TelemetryService{
    appInsights = null;

    constructor(){
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: 'b5d40d3a-17a0-4eed-813e-e05620cf1430',
                maxBatchInterval: 0,
                disableFetchTracking: false
            }
        });

        var telemetryInitializer = (envelope) => {
            envelope.tags["ai.cloud.role"] = "practiceloop-viewer";
        };

        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    };

    setUser = (userId) => {
        var telemetryInitializer = (envelope) => {
            envelope.tags["auth0.userid"] = userId;
        };

        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }

    trackMilestone = (name, properties) => {
        this.trackEvent(`MLSTN - ${name}`, properties);
    }

    trackEvent = (name, properties) => {
        if (!this.appInsights) {
            throw Error('No telemetry provider!');
        }

        let args = {name: name};

        if (properties) {
            args.properties = properties;
        }
        
        this.appInsights.trackEvent(args);
    }
}

export let telemetryService = new TelemetryService();