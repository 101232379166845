import React, { useState, useRef, useEffect } from 'react'

const imageContainerStyleLandscape = {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%'
};

const imageContainerStylePortrait = {
    display: 'inline-flex',
    justifyContent: 'center'
};

function ImagePanelWrapper(props) {
    const containerRef = useRef(null);

    const [dimensions, setDimensions] = useState({
        width: 10000,
        height: 10000,
        orientation: "landscape"
    });

    // This function calculates the width of the image panel
    // The aim is to have the images take up as much space as possible without falling off the window
    // The aspect ratio of the image panel is 1.5 (Width=1.5 * Height) so depending on the dimensions of the window, sometimes the width bounds the size, and sometimes the height
    const calculateLayout = () => {
        var layout = { width: 10000, height: 10000, orientation: "landscape" };

        if (containerRef && containerRef.current) {
            let rect = containerRef.current.getBoundingClientRect();

            // Get the top of the image view / bottom of the heading
            let headerOffset = rect.top;

            // Get the height of the window
            let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (windowWidth < ((windowHeight))) {
                layout.orientation = "portrait";

                // If the bottom of the images is below the bottom of the window
                if (rect.bottom >= windowHeight - headerOffset) {
                    // Then limit the width of the images so that it doesn't fall off the page
                    // The aspect ratio of the 2 images is 1.5
                    layout.width = Math.round((windowHeight - headerOffset) * 0.75);
                    return layout;
                }

                layout.width = windowWidth + "px";
                    return layout;
            }
            else {
                layout.orientation = "landscape";

                // If the bottom of the images is below the bottom of the window
                if (rect.bottom >= windowHeight - headerOffset) {
                    // Then limit the width of the images so that it doesn't fall off the page
                    // The aspect ratio of the 2 images is 1.5
                    layout.width = Math.round((windowHeight - headerOffset) * 1.5);
                    return layout;
                }

                // The user has scrolled so that the image is at the top of the page, so set it to the full window height
                layout.width = Math.round(windowHeight * 1.5);
                return layout;
            }



        }

        // Otherwise, don't limit the width of the images
        return layout;
    }

    useEffect(() => {
        function handleResize() {
            setDimensions(
                calculateLayout()
            )
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    })

    var appliedStyle = dimensions.orientation === "landscape" ?
        { ...imageContainerStyleLandscape, maxWidth: dimensions.width + "px" } :
        { ...imageContainerStylePortrait, width: dimensions.width + "px" };

    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <div ref={containerRef} style={appliedStyle} className={`view${dimensions.orientation}`} >{props.children}</div>
        </div>
    )
}

export default ImagePanelWrapper;