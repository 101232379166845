import { call, takeLatest, select, put } from 'redux-saga/effects';
import { telemetryService } from '../services/telemetryService';
import { servicesHost } from '../environment';
import { setPracticeView } from '../actions/practiceView';
import { Actions } from '../constants';

const axios = require('axios');

const viewChangeActions = ["ENTER_REALTIME_MODE", "ENTER_YOUTUBE_MODE", "ENTER_SINGLE_CHECKPOINT_MODE", "ENTER_DOUBLE_CHECKPOINT_MODE", "SET_CHECKPOINT"];

// Listen for any actions that we want to update the database for
function* savePracticeView() {
  yield takeLatest(viewChangeActions, onSavePracticeView);
}

function* loadPracticeView() {
  yield takeLatest(Actions.Connection.SetIdToken, onLoadPracticeView);
}

function* onSavePracticeView() {
  const getView = (state) => state.practiceView;
  const getToken = (state) => state.user.token;

  try {
    const view = yield select(getView);
    const token = yield select(getToken);
    telemetryService.trackEvent("Trying to save practice view");
    yield call(() => performSave(token, view));
    telemetryService.trackEvent("Saved practice view");
  }
  catch (e) {
    telemetryService.trackEvent("Try id token failed");
    return;
  }
}

async function performSave(token, view) {
  var requestBody = {
    lhs: { type: view.lhs.type },
    rhs: { type: view.rhs.type },
    checkpoints: {}
  };

  if (view.checkpoints.checkpoint1) {
    requestBody.checkpoints.checkpoint1 = {
      id: view.checkpoints.checkpoint1.id,
      name: view.checkpoints.checkpoint1.name,
      image: view.checkpoints.checkpoint1.image
    }
  };

  if (view.checkpoints.checkpoint2) {
    requestBody.checkpoints.checkpoint2 = {
      id: view.checkpoints.checkpoint2.id,
      name: view.checkpoints.checkpoint2.name,
      image: view.checkpoints.checkpoint2.image
    }
  };

  return axios({
    method: "post",
    url: `${servicesHost}/practiceView`,
    data: requestBody,
    headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
  }).then(({ data }) => {
    return data;
  })
}

function* onLoadPracticeView() {
  const getToken = (state) => state.user.token;

  try {
    const token = yield select(getToken);
    telemetryService.trackEvent("Trying to load practice view");
    var view = yield call(() => performLoad(token));
    telemetryService.trackEvent("Loaded practice view");
    yield put(setPracticeView(view));
  }
  catch (e) {
    telemetryService.trackEvent("Load practice view failed");
    return;
  }
}

function performLoad(token) {
  return axios({
    method: "get",
    url: `${servicesHost}/practiceView`,
    headers: { "Authorization": `Bearer ${token}` },
  }).then(({ data }) => {
    return data;
  })
}

export { savePracticeView, loadPracticeView }